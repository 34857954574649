<template>
  <v-snackbar :color="color" timeout="3500" v-model="snackbar">
    {{ message }}
  </v-snackbar>
</template>
<script type="text/javascript">
export default {
  data() {
    return {};
  },
  computed: {
    snackbar: {
      get() {
        return this.$store.getters["options/getOptions"].snackbar;
      },
      set(value) {
        this.$store.dispatch("options/setOptionsData", { snackbar: value });
      }
    },
    message: {
      get() {
        return this.$store.getters["options/getOptions"].message;
      },
      set() {}
    },
    color: {
      get() {
        return this.$store.getters["options/getOptions"].color;
      },
      set() {}
    }
  },
  methods: {
    setSnackbarFalse() {
      this.$store.dispatch("options/setOptionsData", { snackbar: false });
    }
  }
};
</script>
